import React from 'react';
import { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import Scroll from '../../../components/Scroll';
import MS_BG from '../../../assets/images/MS_Background.png';
import CourseCard from '../../../components/CourseCard';

const MississippiEngineering = () => {
  const [width, setWidth] = useState(200);
  const [test, setTest] = useState('');

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  const links = [];
  const helmetMeta = [
    {
      name: 'description',
      content: 'Advance Continuing Education offers license renewal courses for Mississippi engineers.',
    },
    {
      name: 'keywords',
      content:
        'Mississippi Engineering license renewal, Mississippi PE license renewal, Mississippi Engineers Mandatory ce, Mississippi engineers ethics course , PDH Mississippi Engineers',
    },
  ];

  return (
    <Layout links={links} logoUrl="/MS/engineering" helmetMeta={helmetMeta} helmetTitle="MS PE License Renewal">
      <section
        id="banner"
        style={{
          backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.6), rgba(0,0,0,0.6)),url(${MS_BG})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="inner">
          <h2>MISSISSIPPI PE License Renewal</h2>
          {/* <p style={{ fontSize: 15 }}>
            PROFESSIONALLY CURATED CONTENT FOR LICENSE RENEWAL
          </p> */}
          <h3
            style={{
              color: '#ff6400',
              marginBottom: 15,
              textDecoration: 'underline',
            }}
          >
            INCLUDES MANDATORY TOPIC
          </h3>
          <h4 style={{ color: '#ff6400', fontSize: '1.1em' }}>PROFESSIONAL ETHICS - 1 PDH</h4>
          {/* <h4 style={{ color: '#ff6400', fontSize: '1.1em' }}>
            BLOCKCHAIN IN ENGINEERING - AN EMERGING TREND - 3 PDH
          </h4> */}
          <h4 style={{ color: '#019cff', marginBottom: 10 }}>
            Course 1 (1PDH) <s style={{ color: 'red' }}>$30</s> $20
          </h4>
          <h4 style={{ color: '#019cff', marginBottom: '2em' }}>
            Entire Program (4PDH) <s style={{ color: 'red' }}>$79</s> $40
          </h4>
          <ul className="actions special">
            <li>
              <a
                href="https://storage.googleapis.com/advancece/Engineering/Mississippi/Mississippi%20PE%20Licensure%20Program.pdf"
                target="_blank"
                className="button primary"
                style={{ backgroundColor: '#ff6400' }}
              >
                View Course Materials
              </a>
            </li>
            <li>
              <a className="button secondary" href="/MS/Engineering/MSENG41">
                Take Test
              </a>
            </li>
          </ul>
        </div>
        <Scroll type="id" element="getting-started">
          <a href="#getting-started" className="more">
            Learn More
          </a>
        </Scroll>
      </section>
      <section
        id="getting-started"
        className="wrapper style2 special"
        style={{
          backgroundColor: '#019cff',
          minHeight: 400,
          maxHeight: 590,
        }}
      >
        <div className="inner">
          <header>
            <h2 style={{ color: 'white', fontSize: 20 }}>Renew Your Mississippi Engineering License with Advance!</h2>
            <p style={{ textAlign: 'left', color: 'white' }}>
              Don't let your engineering license expire! Here at Advance CE, we offer everything you need to seamlessly renew your PE
              license, sharpen your knowledge, and Advance your career. We provide license renewal courses that cater to professional
              engineers. We have curated content designed to satisfy your Professional Development Hours (PDH) including the Mississippi
              Ethics course.
            </p>
            <h2 style={{ color: 'white', marginBottom: 15 }}>GETTING STARTED</h2>
            <ol style={{ textAlign: 'left' }}>
              <li>
                <span style={{ color: 'white', fontWeight: 'bold' }}>Select “Take Test”</span> option on the home page or in the sidebar
              </li>
              <li>
                <span style={{ color: 'white', fontWeight: 'bold' }}>Fill in the answers</span> for all courses you’d like to receive credit
                for, hit “Submit”
              </li>
              <li>
                <span style={{ color: 'white', fontWeight: 'bold' }}>Create an account</span> or sign in
              </li>
              <li>
                <span style={{ color: 'white', fontWeight: 'bold' }}>Input</span> your license and payment information, and then complete
                the evaluation
              </li>
              <li>
                <span style={{ color: 'white', fontWeight: 'bold' }}>Receive your certificate</span> instantly via email
              </li>
            </ol>
          </header>
        </div>
      </section>
      <section id="one" className="wrapper style2 special" style={{ minHeight: 600 }}>
        <div
          className="inner"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <header className="major">
            <h2>Engineering Materials</h2>
          </header>
          <CourseCard
            title="MISSISSIPPI PE License Renewal"
            price={`Course 1 (1PDH): $20 \nEntire Program (4PDH): $40`}
            href="https://storage.googleapis.com/advancece/Engineering/Mississippi/Mississippi%20PE%20Licensure%20Program.pdf"
          />
        </div>
      </section>

      {/* <section id="three" className="wrapper style3 special">
        <div className="inner">
          <header className="major">
            <h2>Engineering Tests</h2>
            <p>
              Advance offers the following tests for engineers in the great
              state of Florida
              <br />
              Have a book code already? Enter it here to begin taking the test
            </p>
          </header>
          <ul className="features">
            <li className="icon fa-paper-plane">
              <h3>Enter Book Code</h3>
              <p>Enter your book code to be taken to the test.</p>
              <input
                className="primary"
                type="text"
                placeholder="FL0013"
                onChange={(e) => setTest(e.target.value)}
              />

              <a
                className="button secondary"
                style={{ width: '100%', marginTop: 10 }}
                onClick={() =>
                  (window.location.href = `/FL/Engineering/${test}`)
                }
              >
                Take Test
              </a>
            </li>
            <li className="icon solid fa-laptop">
              <h3>Test Quiz 1</h3>
              <a
                className="button secondary"
                style={{ width: '100%', marginTop: 10 }}
                onClick={() =>
                  (window.location.href = `/FL/Engineering/FLENG51`)
                }
              >
                Take Test
              </a>
            </li>
          </ul>
        </div>
      </section> */}

      <section id="cta" className="wrapper style4">
        <div className="inner">
          <header>
            <h3>Have a Question? Contact Us!</h3>
            <p>A representative will respond to you as soon as possible.</p>
          </header>
          <ul className="actions stacked">
            <li>
              <a href="/ContactUs" className="button fit primary">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </section>
    </Layout>
  );
};

export default MississippiEngineering;
